// @flow strict
import React from 'react';
import styles from './Imprint.module.scss';

type Props = {
    imprint: string
};

const Imprint = ({ imprint }: Props) => (
    <div className={styles['imprint']}>
        <br />
        <a href={imprint}>Impressum</a>
    </div>
);

export default Imprint;